.bc-main-theme {
  position: relative;

  > * {
    position: relative;
    z-index: 1;
  }

  &:before {
    position: absolute;
    z-index: 0;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url('/.galleries/template/slider-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    filter: hue-rotate(35deg) saturate(0.8) grayscale(0.1);
  }

  &-transparent {
    &:before {
      opacity: 0.7;
    }
  }

  &-transparent-light {
    &:before {
      opacity: 0.3;
    }
  }

  &-v1 {
    &:before {
      background-size: auto;
      background-position-x: 21%;
      background-position-y: top;
    }
  }

  &-v2 {
    &:before {
      background-size: auto;
      background-position-x: 85%;
      background-position-y: top;
    }
  }

  &-v3 {
    &:before {
      background-size: auto;
      background-position-x: 15%;
      background-position-y: bottom;
    }
  }

  &-v4 {
    &:before {
      background-position-x: center;
      background-position-y: bottom;
      background-size: auto;
    }
  }
}

//стрелки для стандартного слайдера
.slider-res {
  .slick-arrow {
    &:before {
      width: 30px;
      height: 30px;
      background-color: $dark-15;
      color: $dark-90;
      box-shadow: 0 2px 10px rgba(32, 42, 68, 0.08);
      border-radius: 3px;
      opacity: 0.9;
      background-size: auto;
      background-repeat: no-repeat;
      background-position: center;

      &:hover {
        opacity: 1;
      }
    ;
    }
  }

  .slick-next {
    &:before {
      content: "";
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9' height='16' viewBox='0 0 9 16' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6.1548 8.00031L0.0773926 1.9229L1.2559 0.744385L8.51183 8.00031L1.2559 15.2562L0.0773926 14.0777L6.1548 8.00031Z' fill='%23374057'/%3E%3C/svg%3E");
    }
  }

  .slick-prev {
    &:before {
      content: "";
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9' height='16' viewBox='0 0 9 16' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.8452 8.00031L8.92261 1.9229L7.7441 0.744385L0.488173 8.00031L7.7441 15.2562L8.92261 14.0777L2.8452 8.00031Z' fill='%23374057'/%3E%3C/svg%3E");
    }
  }
}

.slider-res {
  .slick-arrow {
    height: 30px;
    width: 30px;
    top: -26px;

    &:before {
      background-color: $white;
      color: $dark-80;
      box-shadow: 0 2px 10px rgba(32, 42, 68, 0.08);
    }
  }

  .slick-next {
    left: inherit;
    right: 10px;
  }

  .slick-prev {
    left: inherit;
    right: 55px;
  }
}

//слайдер ресурсов
.slider-res {
  .slick-slide img {
    display: inline;
  }

  .slide-wrapper .visual {
    width: inherit;
    margin: 0 !important;
    padding: 10px;
  }
}

.slider-banners .slick-initialized .slick-slide {
  //display: inline-block;
  //vertical-align: middle;
  //padding: 0 15px;
  //float: none;

  display: table-cell;
  vertical-align: middle;
  padding: 5px 10px;
  height: 100%;
  float: none;
  border: 5px solid white;
  border-width: 5px 15px;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    border: 1px solid $dark-10;
    transition: border-color 0.3s;
  }

  &:hover {
    &::before {
      border-color: $dark-40;
    }
  }
}

.box-shadow {
  box-shadow: 0px 2px 10px rgba(32, 42, 68, 0.08);
}

.box-hover-shadow {
  transition: box-shadow .3s;

  &:hover {
    border-color: transparent !important;
    box-shadow: 0px 10px 30px rgba(32, 42, 68, 0.1);
  }

}

.arrow-link, .arrow-link-wrapper .piece-text-link {
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  color: $dark-70;

  &::after {
    display: inline-block;
    content: "";
    margin-left: 10px;
    vertical-align: text-bottom;

    //@include icon-arrow-right($dark-20);
  }

  .type-productbox:hover &,
  &:hover {
    color: $dark-90;

    &::after {
      //@include icon-arrow-right($green);
    }
  }
}

.outline-link {
  .btn {
    @include button-outline-variant($red);
  }
}


.icon-title {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  margin: 0;

  + .icon-text {
    margin-top: 15px;
  }
}

.box-main {
  padding: 0 15px 20px;
  background-color: #fff;
  border-radius: 3px;
  transition: box-shadow .3s;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);

  font-family: $font-family-headings;

  &:hover {
    border-color: transparent !important;
    box-shadow: 0 4px 13px 0 rgba(0, 0, 0, 0.05);
  }

  &.piece > .visual {
    width: inherit;
    margin-left: -$grid-gutter-width/2;
    margin-right: -$grid-gutter-width/2;
  }

  .image-src-box {
    border-radius: $image-border-radius $image-border-radius 0 0;
  }
}

.square-col .content {
  @extend .box-main;
  padding: 0;
}

//.teaser-elaborate:not(.box-main) {
//  padding: 0 !important;
//}
.teaser-masonry {
  @extend .box-main;
  width: auto !important;

  &.piece > .visual + &.piece > .body {
    margin-top: 1rem;
  }

  &.piece > .body {
    flex-grow: 1 !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .teaser-date {
    margin-bottom: 0;
  }
}

.teaser {
  a > .headline {
    color: $link-color;
  }

  a:hover > .headline {
    color: $link-hover-color;
  }

  .teaser-detail-headline {
    font-size: 1.8rem;
    float: left;
    width: 100%;
    margin: 1.5rem 2rem;

    &:after {
      display: block;
      margin: 1.5rem auto;
      height: 3px;
      width: 100px;
    }
  }

  .teaser-detail-date {
    background-color: $dark-5;
    padding: .75em 1rem .6em;
  }

  .teaser-detail-date, .teaser-date-range {
    color: $blue-dark;
    font-size: 1.5rem;
    margin-bottom: 0;

    &:before {
      display: inline-block;
      font: normal normal normal 14px/1 FontAwesome;
      font-size: 1.8rem;
      text-rendering: auto;
      vertical-align: text-bottom;
      -webkit-font-smoothing: antialiased;
      content: "\f274";
      margin-right: 0.2rem;

      color: $blue-dark;
    }
  }

  .teaser-detail-text {
    border: 3px solid $dark-5;
    padding: 1.5rem 2rem;
    margin-bottom: 2rem;
  }

  .list-entry & {
    .teaser-detail-text {
      margin-bottom: -2rem;
    }
  }


  .list-expertise & {
    border-bottom: 1px solid $dark-5;
    padding-bottom: 2rem;
    margin-bottom: 2rem;
  }
}

.box-main-list .teaser.teaser-text-tile {
  @extend .box-main;
  width: auto !important;

  min-width: -webkit-fill-available;

  &.piece > .body {
    flex-grow: 1 !important;
    display: flex;
    flex-direction: column;
  }

  .teaser-date {
    margin-bottom: 0;
  }
}

.text-box,
.text {
  //списки с ромбиками
  ul {
    margin-left: 2rem;
    padding-left: 0;
    margin-bottom: $grid-gutter-width;

    li {
      list-style: none;
      position: relative;

      &:before {
        content: "";
        position: absolute;
        width: 0.5rem;
        height: 0.5rem;
        background: $green;
        margin-left: -2rem;
        top: 0.8rem;
      }

      margin-bottom: 1.5rem;
    }
  }

  //списки с циферками
  .list-counter {
    margin-left: $grid-gutter-width * 2;
    counter-reset: list 0;

    li {
      position: relative;
      margin-bottom: $grid-gutter-width / 2;
      padding-top: 11px;
      min-height: 43px;

      &:last-child {
        &:before {
          display: none;
        }
      }

      &:before {
        position: absolute;
        content: "";
        width: 1px;
        top: 0;
        bottom: -$grid-gutter-width / 2;
        background: $grey;
        left: (-$grid-gutter-width * 2) + ((($grid-gutter-width * 1.5) / 2) - 2);
        transform: none;
        height: auto;
      }

      &:after {
        position: absolute;
        display: inline-flex;
        left: -$grid-gutter-width * 2;
        top: 0;
        counter-increment: list;
        content: counter(list) " ";
        width: $grid-gutter-width * 1.5;
        height: $grid-gutter-width * 1.5;
        box-sizing: border-box;
        border-radius: 100px;
        border: 1px solid $green;
        background: $white;
        color: $dark;
        margin-bottom: $grid-gutter-width / 2;
        transform: none;
        font-size: 15px;
        line-height: 43px;
        justify-content: center;
        font-weight: bold;
      }
    }

  }

  img {
    margin: 0.5rem;
  }

  table {
    &[border] {
      border: 1px solid $gray-400;
    }

    tr {
      th {
        border-bottom-width: 2px;
      }

      th,
      td {
        padding: 1rem;
        border-color: $gray-400;
      }
    }
  }

  p + hr {
    margin: 2rem 0;
    clear: both;
  }
}

.ls-navigation {
  .text-box {
    ul li {
      margin-bottom: 0.75rem;

      a {
        padding-left: 0;
      }
    }
  }
}

.ls-links {
  .nav-side li {
    border-top: none;

    > a {
      border-bottom: 1px solid $dark-5;
    }

    &:last-child > a {
      border-bottom: none;
    }
  }
}

.ls-item__extension {
  display: inline-block;
  padding: 1px 4px 1px 3px;
  margin-right: 6px;
  margin-left: 3px;
  font-size: 11px;
  line-height: 14px;
  color: #fff !important;
  text-transform: uppercase;
  letter-spacing: .5px;
  vertical-align: middle;
  background: #939598;
  border: none !important;

  &.word,
  &.doc,
  &.docx {
    background: #00539e;
  }

  &.excel,
  &.xls,
  &.xlsx {
    background: #2e9b00;
  }

  &.powerpoint,
  &.ppt,
  &.pptx {
    background: #d25237;
  }

  &.pdf {
    background: #ce0414;
  }

  &.tif,
  &.tiff {
    background: #e48700;
  }

  &.access {
    background: #c42052;
  }

  &.archive,
  &.zip,
  &.rar {
    background: #722782;
  }

  &.text,
  &.txt {
    background: #48768c;
  }
}


.b-logo {
  display: flex;
  flex-direction: row;
  align-items: center;

  img {
    margin-right: $grid-gutter-width/2;
    max-width: 73px;
    height: auto;

    .isfixed & {
      max-width: 35px;
    }
  }

  h1 {
    flex-grow: 1;
    color: $blue-dark;
    font-size: 1.8rem;
    line-height: 1.3;
    margin: 0 0 5px;
    font-weight: 800;
    letter-spacing: 0.5px;

    @include media-breakpoint-down(sm) {
      font-size: 1.6rem;
    }

    small {
      display: block;
      color: $dark;
      font-size: 1.3rem;
      margin-bottom: $grid-gutter-width/4;
      letter-spacing: 0;

      .isfixed & {
        display: none;
      }

      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
  }
}

.h-group {
  //&:after {
  //  position: absolute;
  //  top: 0;
  //  right: -180px;
  //  z-index: 1;
  //  display: block;
  //  width: 736px;
  //  height: 460px;
  //  content: "";
  //  background: url(/.galleries/template/plane.png) no-repeat 0 0;
  //}
}

.isfixed {
  .fixed-hidden {
    display: none !important;
  }

  .h-logo, .h-info {
    margin-top: 0.5rem !important;
  }

  .element {
    margin-bottom: 0.5rem !important;
  }

  .blind-mode-link {
    margin-bottom: 0 !important;
  }
}

.attachment-container {
  clear: both;
}

.area-content:not([class*='bc-']) {
  > .container > .row > .col-lg-9 {
    background-color: #fff;
    border-radius: 3px;
    transition: box-shadow .3s;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
    margin-bottom: 3rem;
    padding-top: 1rem;
  }
  @include media-breakpoint-up (lg) {
    > .container > .row > .col-lg-9 {
      flex: 0 0 73.5%;
      max-width: 73.5%;
      padding: 15px 30px 30px 20px;
      margin-right: 1.5%;
      margin-bottom: 0;

      &.order-lg-last {
        margin-left: 1.5%;
        margin-right: 0;
      }
    }
  }

  > .container > .row > .col-lg-3 {
    @include media-breakpoint-down (lg) {
      padding: 0;
    }
  }
}

.area-header {
  position: relative;

  &:before {
    position: absolute;
    z-index: 0;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    min-height: 130px;
    background-image: url('/.galleries/template/stupino-head-bg.png');
    background-repeat: repeat-x;
    background-position: 55% center;

    @include media-breakpoint-down(lg) {
      background-position: 22% center;
    }
    @include media-breakpoint-down(md) {
      background-position: 13% center;
    }
    @include media-breakpoint-down(sm) {
      background-position: -20% center;
    }
  }

  @include media-breakpoint-down(lg) {
    .blind-mode-link > span {
      display: none;
    }
  }

  &.fh .h-logo {
    @include media-breakpoint-down(lg) {
      margin-top: 0;
      padding-top: 1rem;
    }
  }

  .head {
    background: transparent;

    .h-group {
      position: relative;
      max-height: 130px;
    }
  }

  .h-nav {
    position: relative;
    z-index: $above-oc-z-index;
  }
}
col-lg-9
.search-facets {
  .type-list-filter {
    padding: $grid-gutter-width/4 $grid-gutter-width/2 $grid-gutter-width/2;
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 0 4px 13px 0 rgba(0, 0, 0, 0.05);
    font-family: $font-family-headings;
  }
}

.search-results {
  .search-result {
    padding: $grid-gutter-width/2;
    background-color: #fff;
    margin-bottom: 0;
  }

  .list-append-position {
    text-align: center;
    margin: $grid-gutter-width/2 0;
  }
}
//скрыть в мобильном меню ссылки с шапки
#nav-main-addition {
  display: none;
}

//скрываем ссылку для слепых на мобильной версии
.js-blind-controls {
  @include media-breakpoint-down (md) {
    display: none;
  }
}

// кнопки скачивания нормативных документов
.type-normative-document {
  .documents {
    .btn {
      width: 145px;
    }
  }
}