/**********
 *
 * Polls
 */

.poll {
  margin-top: 1.6em;

  &__viewtype_closed {

  }

  &__description {
    @extend .lead;
  }

  &__form {
  }

  &__item {
    margin: 0;

    &_title {
      padding: 0.5em 1em;
      //padding: ($line-height-headings/2);
      font: {
        //family: $font-header;
        size: 1.3em;
        weight: 500;
      };
      background: $blue-lighter;

      p:last-child {
        margin-bottom: 0;
      }
    }

    &_content {
      font: {
        size: 1.1em;
      };
    }
  }

  &__option {
    position: relative;
    border-bottom: 1px dotted #eee;
    //padding: 1px ($line-height-headings/2);
    margin: 20px 0;
    transition: background-color 0.33s;

    &:last-child {
      border-bottom: none;
    }

    &:hover {
      background-color: #FBFBFB;
    }

    &_type {
      &_result{
        padding: 2em;
      }
      &_win {
        font: {
          weight: 700;
        };
      }
    }

    &_title {
      font-weight: bold;
    }

    &_content {
      font: {
        size: 1em;
      }
    ;
    }
  }

  &__footer {
    //padding-top: $line-height-headings;
    padding-top: 2em;
    //margin: $line-height-headings 0;
    border-top: 3px solid $nav-side-border;
    font: {
      //family: $font-header;
      size: 1.3em;
    }

    span.label {
      font-size: inherit;
    }
  }

  &__result {
    background-color: #f0f0f0;
    height: 10px;
    margin: 4px 0 10px;
    position: relative;

    &_value {
      font:{
        //family: $font-header-bold;
        weight: 600;
        size: 1em;
      };
      float: right;
      margin-left: 20px;

      span {
        color: #999;
      }
    }

    &_bar {
      height: 100%;
      position: absolute;
      left: 0;

      &_1 {
        background-color: $cyan;
      }
      &_2 {
        background-color: $green-light;
      }
      &_3 {
        background-color: $yellow;
      }
      &_4 {
        background-color: $orange;
      }
      &_5 {
        background-color: $red-orange;
      }
      &_6 {
        background-color: $red-light;
      }
      &_7 {
        background-color: $violet;
      }
      &_8 {
        background-color: $main-theme ;
      }
      &_9 {
        background-color: $main-theme-hover;
      }
      &_10 {
        background-color: $blue;
      }
    }
  }
}
