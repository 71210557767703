/*комментарии*/

$spacing:       $grid-gutter-width;
$main-color:    $blue-dark;
$sec-color:     $blue;
$bg-color:      $blue-lighter;
$line-color:    $grey-lighter;

.type-dynamic-comments {
    position: relative;
}

.comments {
    .fa-user {
        color: $main-color;
    }
    .error, .text-danger {
        color: $red;
    }
    .text-success {
        color: $green;
    }

    &__title {
        padding-bottom: ($spacing / 4);
        margin: $spacing/2 0 $spacing;
        border-bottom: 3px solid $main-color;
        font: {
            family: $font-weight-bold;
            size: 1.3em;
        }

        .fa {
            color: $blue-dark;
        }

        &_viewtype {
            &_questions {

            }
        }
    }

    &__form {
        margin: ($spacing/2) 0;
        max-width: 500px;

        &_viewtype {
            &_questions {
                max-width: 80%;
            }
        }

        textarea.form-control {
            min-height: 90px;
        }

        .alert {
            margin: 1rem 0;
        }
    }

    &__list {
        list-style: none;
        margin: ($spacing/2) 0;
        overflow: hidden;
        padding: 0 ($spacing/2);
        //width: 95%;

        .comments__reply {
            padding-left: 20px;
            list-style: none;
            clear: both;
        }

        &_viewtype {
            &_questions {
                padding: 0;

                > li {
                    border: 1px solid $line-color;
                    padding: 0 0.5em;
                    margin-bottom: 2em;
                }

                .comments__item_type,
                .comments__item_indicator {
                    display: none !important;
                }

                .comments__item {
                    background: $bg-color;

                    + .comments__reply {
                        padding-left: 0;

                        .comments__item {
                            background: transparent !important;
                            border-bottom: none;

                            &::before {
                                position: absolute;
                                right: 20px;
                                content: "\f112";
                                font: {
                                    family: FontAwesome;
                                    size: 50px;
                                };
                                color: $bg-color;
                                opacity: 0.8;
                                transform: scale(1, -1);
                                z-index: 0;
                            }
                        }
                    }
                }

                .comments__item_header {
                    position: relative;
                    font-size: 1.125em;
                    z-index: 1;
                }

                .comments__item_body {
                    position: relative;
                    font-size: 1.25em;
                    margin-top: ($spacing/2);
                    margin-bottom: ($spacing/2);
                    background: transparent !important;
                    z-index: 1;
                }

            }
        }
    }

    &__item {
        position: relative;
        padding: 0;
        margin: 0 0 0 10px;
        clear: both;

        &:hover {

            > .comments__item_body {
                background: $bg-color;
                border-color: $bg-color;
            }

            > .comments__item_header > .comments__item_indicator {
                display: block;
            }

        }

        &_header {
            position: relative;
            margin-top: ($spacing/2);
        }

        &_date {
            font:{
                size:0.85em;
                family: $font-family-base;
            };
            color: $grey;
            display: inline-block;
            margin-left: ($spacing/4);
            white-space: nowrap;
        }
        &_status {
            &-new,
            &-blocked {
                position: relative;
                clear: both;
                &::before {
                    content: "";
                    display: block;
                    position: absolute;
                    left: -6px;
                    top: 26px;
                    bottom: 0;
                    background: $main-color;
                    width: 3px;
                    clear: both;
                }
            }
            &-blocked {
                &::before {
                    background: $grey;
                }
                .comments__item_header,
                .comments__item_body,
                .comments__tools .fa {
                    border-color: $line-color;
                    color: $grey !important;
                }
            }
        }

        &_indicator {
            display: none;
            height: 1px;
            left: 0;
            position: absolute;
            top: 0;
            width: 1px;

            &::before {
                position: absolute;
                display: block;
                content: '  •  •  •  •  •  •  •  •  •  •  •  •  •  •  •  •  •  •  •  •  •  •  ';
                font-size: 15px;
                line-height: 1;
                letter-spacing: 1px;
                right: 10px;
                top: 3px;
                height: 10px;
                overflow: hidden;
                width: 500px;
                color: $sec-color;
                text-align: right;
            }
        }

        &_body {
            transition: all 0.1s ease-in-out 0s;
            border-radius: 3px;
            padding: ($spacing/4);
            margin: ($spacing/6) (-$spacing/4) ($spacing/4);
            border: 1px dotted $line-color;
        }
        &_attachment {
            font-size: 90%;
            font-style: italic;
            border-top: 1px solid #eeeef0;
            padding-top: $spacing/4;
            margin-top: $spacing/3;
            color: grey;
        }

    }

    &__captcha {
        padding: 0;
    }

    &__answer {
        float: right;
        margin-top: -40px;
        text-align: right;

        &-btn {
            cursor: pointer;
            font-size: 1.5em;
            border-bottom: none;
            text-decoration: none;
            color: $grey-light;

            &:hover {
                border-bottom: none;
                text-decoration: none;
                color: $grey;
            }
        }

        .comments__form {
            margin: 5px -7px 10px 0;
            padding: $spacing;
            background: $line-color;
            border-radius: $btn-border-radius;
            min-width: 440px;
        }
    }

    &__tools {
        float: right;
        margin: 0 -6px 0 20px;

        &-item {
            margin-left: 10px;
            font-size: 1.1em;
            border-bottom: none;
            text-decoration: none;

            &:hover {
                border-bottom: none;
                text-decoration: none;
            }

            span {
                display: none;
            }
        }

        .fa-check {
            color: $green;

            &:hover {
                color: $green-darker;
            }
        }
        .fa-trash {
            color: $red;

            &:hover {
                color: $red-darker;
            }
        }
        .btn-group {
            .btn {
                //float: right;
                margin-bottom: 1px;

                > input[type='checkbox'] {
                    margin: 0;
                    vertical-align: text-bottom;
                }
            }
        }

        //.comments__item_body {


            .comments__item_indicator {
                display: block;
                position: absolute;
                width: 20px;
            }
         
        //}

    }

    &__tooltip {
        position: absolute;
        top: -18px;
        right: 0;
        text-align: right;
        white-space: nowrap;
    }

    &__thumbs {
        float: right;
        &-count {
            color: $grey;
        }
        a {
            border-bottom: none;
            text-decoration: none;
            cursor: pointer;

            &:hover {
                border-bottom: none;
                text-decoration: none;
                color: $grey-dark;

                .fa {
                    color: $grey-dark;
                }

                .comments__thumbs-count {
                    color: $main-color;
                }
            }
            &.active {
                .fa {
                    color: $black;
                }
            }
            .fa {
                margin: 0 $spacing/4;
                color: $grey;
            }
        }
    }
}

.filters {
    background: $bg-color;
    padding: ($spacing/2);
    margin-bottom: ($spacing/2);

    .input-group {
        margin-bottom: ($spacing/4);

        &.date {
            width: 180px;
        }
    }
    .btn {
        margin-bottom: ($spacing/4);
    }

}

.js-action-link {
    cursor: pointer;
}
.float-right {
    float: right;
}
.error {
    color: $red;
}
#comments-admin-form {
    .btn-group > .btn {
        margin-right: 5px;

        &:last-child {
            margin-right: 0;
        }
    }
}
.comments-list-count {
    position: absolute;
    margin-top: -41px;
    right: 0;
}