//Mode bodyclass
$blind: ".page_state_blind";
$blind-wb: ".page_viewtype_schema-wb";
$blind-bw: ".page_viewtype_schema-bw";
$blind-blue: ".page_viewtype_schema-blue";
$blind-fs-xs: ".page_viewtype_size-xs";
$blind-fs-s: ".page_viewtype_size-s";
$blind-fs-m: ".page_viewtype_size-m";
$blind-fs-l: ".page_viewtype_size-l";
$blind-fk-xs: ".page_viewtype_kerning-xs";
$blind-fk-s: ".page_viewtype_kerning-s";
$blind-fk-m: ".page_viewtype_kerning-m";
$blind-fk-l: ".page_viewtype_kerning-l";
$blind-images-true: ".page_viewtype_images-true";
$blind-images-false: ".page_viewtype_images-false";

//настройка режима для слабовидящих
#{$blind}#{$blind-wb} .blind-mode,
#{$blind}#{$blind-bw} .blind-mode,
#{$blind}#{$blind-blue} .blind-mode,
.blind-mode {
  min-height: 4em;
  font-size: 18px;
  color: white;
  letter-spacing: 0;
  background-color: #5a5a5a;
  background: -webkit-linear-gradient(top, #5a5a5a 0, #383838 100%);
  background: -ms-linear-gradient(top, #5a5a5a 0, #383838 100%);
  background: linear-gradient(to bottom, #5a5a5a 0, #383838 100%);
  font-family: $font-family-base;
  padding-bottom: 15px;
  z-index: 1000;

  a {
    color: white;
    border-bottom: 1px solid white;
  }

  &-link,
  &__link {
    color: $dark-70;

    > svg {
      width: 2rem;
      max-height: 2rem; //ie fix
      margin-right: 0.75rem;
    }

    > span {
      font-size: 1.6rem;
    }
  }

  .control-group {
    display: table-cell;
    padding-right: 2em;
    padding-top: 0.3em;
    vertical-align: top;

    small {
      display: inline-block;
      margin-bottom: 10px;
      font-size: 75%;
    }
  }

  .pull-right {
    padding-top: 0.45em;

    .btn.btn-default {
      line-height: 1.35;
      height: auto;
      padding: 10px 0;
    }
  }

  .btn.btn-default {
    border-radius: 2px;
    padding: 0 10px;
    margin-right: 6px;
    font-size: 18px;
    font-weight: normal;
    line-height: 40px;
    height: 40px;
    //vertical-align: top;
    -webkit-box-shadow: none;
    box-shadow: none;


    color: white !important;
    background: none !important;
    border: 2px solid transparent !important;

    &.active {
      border-color: white !important;
    }


    .fa {
      color: white !important;
    }

    &:hover {
      .fa {
        color: white !important;
      }
    }

    > b {
      font-weight: 500;
    }

    &.btn-fs-xs > b {
      font-size: 18px;
    }

    &.btn-fk-xs > b {
      letter-spacing: 0;
    }

    &.btn-fs-s > b {
      font-size: 23px;
    }

    &.btn-fk-s > b {
      letter-spacing: 2px;
    }

    &.btn-fs-m > b {
      font-size: 28px;
    }

    &.btn-fk-m > b {
      letter-spacing: 4px;
    }

    &.btn-fs-l > b {
      font-size: 33px;
    }

    &.btn-fk-l > b {
      letter-spacing: 6px;
    }


    &.btn-wb {
      padding: 0 10px;
      color: black !important;
      background: white !important;
      border: 2px solid black !important;
    }

    &.btn-bw {
      padding: 0 10px;
      color: white !important;
      background: black !important;
      border: 2px solid white !important;
    }

    &.btn-blue {
      padding: 0 10px;
      color: #063462 !important;
      background: #9dd1ff !important;
      border: 2px solid #9dd1ff !important;
    }
  }

}

@mixin schema($color: rgba(0,0,0,1), $color-bg: rgba(255,255,255,1)) {
  color: $color;
  background: $color-bg;

  #apollo-page,
  .area-header .head,
  .area-foot .topfoot,
  .area-foot .subfoot,
  .area-header .sticky.always > .isfixed,
  .area-header .sticky.csssetting > .isfixed,
  .area-header .sticky.upscroll > .isfixed,
  .nav-menu,
  .nav-menu > li > a {
    color: $color;
    background: $color-bg;
  }

  .h-group {
    display: none;
  }

  .h-nav {
    padding-top: 1.2rem;
    color: $color !important;
    background: $color-bg !important;
  }

  .area-header .sticky.always > .isfixed {
    border-bottom: 2px solid $color;
  }

  img {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
  }

  a,
  .text-success,
  .text-danger,
  .text-warning,
  .text-info,
  .text-dark,
  .text-light,
  .dl-horizontal dt,
  .teaser-date,
  .detail-page .detail-visual .visual-info .info > div,
  .nav-side li > a:not(.btn),
  h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6,
  .teaser .headline,
  .teaser:hover .headline {
    color: $color !important;
  }

  h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    small, .small {
      color: $color;
    }
  }

  table.table {
    border: 1px solid $color;
    color: $color;
    background: $color-bg;

    tr, td, th {
      border: 1px solid $color !important;
      color: $color;
      background: $color-bg;
    }
  }

  a {
    border-bottom-color: $color;
    color: $color;

    &:hover {
      border-bottom-color: $color;
      color: $color;
      text-decoration: underline !important;

      u {
        color: $color;
        border-bottom-color: $color;
      }
    }

    u {
      color: $color;
      border-bottom-color: $color;
    }
  }

  [aria-expanded]>a::after {
    color: $color !important;
  }

  [class*="box-"] {
    color: $color !important;
    background-color: $color-bg !important;
    background-image: none;
    border: 2px solid $color !important;
  }

  .btn,
  .btn-default,
  .btn-primary,
  .btn-success,
  .btn-info,
  .btn-warning,
  .btn-danger {
    color: $color !important;
    background-color: $color-bg !important;
    background-image: none;
    border: 2px solid $color !important;

    .fa {
      color: $color !important;
    }

    &:hover,
    &:active,
    &:focus {
      background: $color !important;
      color: $color-bg !important;

      .fa {
        color: $color-bg !important;
      }
    }

    .badge {
      background: $color;
      color: $color-bg;
    }
  }

  .box-main,
  .box-main-list .teaser.teaser-text-tile,
  .square-col .content, .teaser-masonry {
    color: $color !important;
    background: transparent !important;
  }

  .alert {
    background: none;
    border-color: $color !important;
    color: $color !important;

    a {
      color: $color;

      &:hover {
        color: $color;
      }
    }
  }

  .styled-form {
    background-color: transparent;
  }
  .form-control,
  select,
  textarea,
  .input[type='text'],
  .input[type='serach'],
  .input[type='password'],
  .styled-form .input input,
  .styled-form .select select,
  .styled-form .textarea textarea {
    border: 2px solid $color;
    color: $color;
    background-color: transparent;

    // Firefox
    &::-moz-placeholder {
      color: $color;
      opacity: 1; // See https://github.com/twbs/bootstrap/pull/11526
    }

    &:-ms-input-placeholder {
      color: $color;
    }

    // Internet Explorer 10+
    &::-webkit-input-placeholder {
      color: $color;
    }

    // Safari and Chrome
  }

  #nav-main-search {
    .nav-menu {
      border: 2px solid $color;
      background: $color-bg;
      padding: 2rem;
    }
    .search-form {
      border-bottom: none;

      input {
        color: $color;

        &::placeholder {
          color: $color;
        }
      }

      .btn {
        background: transparent !important;
        border: none !important;
        font-size: 0;

        &:hover {
          background: transparent !important;
          border: none !important;
        }

        &:after {
          font-size: 2rem;
          color: $color;
        }
      }
    }
  }

  input[type="submit"] {
    border: 2px solid $color;
    color: $color;
    background: $color-bg;

    &:hover,
    &:active,
    &:focus {
      background: $color;
      color: $color-bg;
    }
  }

  .modal-dialog {
    border: 1px solid $color;

    .close {
      color: $color;
    }

    .modal-header,
    .modal-body,
    .modal-footer {
      background: $color-bg;
      color: $color;
      border-color: $color;
    }
  }

  .ap-header {
    background: none;

    .head {
      border-color: $color;
    }

    .nav {
      > li > a {
        background: none !important;
        border-color: $color !important;
        color: $color !important;
      }

      .dropdown-menu {
        border: 2px solid $color;

        > li > a {
          border-color: $color !important;
          background-color: $color-bg !important;
          color: $color !important;
        }

      }

      #searchButtonHeader .search {
        color: $color;
        border-color: $color;
      }
    }

    .search-open {
      border-color: $color;
    }
  }

  .ap-iconbox {
    background: none;
    border: 2px solid $color;
    color: $color;

    *:not(a):not(span):not(.btn) {
      color: $color;
    }

    a:not(.btn) {
      color: $color;

      &:hover {
        color: $color;
      }
    }
  }

  .breadcrumbs {
    background: none;
    border-top: 2px solid $color;
    border-bottom: 2px solid $color;

    &::before {
      display: none;
    }
  }

  .detail-icon {
    color: $color !important;
    border-color: $color !important;
  }

  .headline {
    h2, h3, h4 {
      border-color: $color !important;
    }
  }

  .bg-copyright {
    border-top: 2px solid $color;
    background: none;
  }

  .area-foot {
    background: none;
    color: $color;
    border-top: 2px solid $color;
    border-bottom: 2px solid $color;

    *:not(a):not(span):not(.btn) {
      color: $color;
    }

    a:not(.btn) {
      color: $color;

      .fa-stack-1x {
        color: $color-bg;

        &:hover {
          color: $color-bg;
        }
      }

      &:hover {
        color: $color;
      }
    }
  }

  .filterbox {
    background: $color-bg;
    border: 1px solid $color;
  }

  .sidebar-nav {
    border: 1px solid $color;

    li {
      border-color: $color;
      background: $color-bg;

      &.currentpage {
        background: $color;

        > a {
          color: $color-bg;
        }
      }
    }
  }

  .bg-area-yellow,
  .bg-area-green,
  .bg-area-blue,
  [class*="bc-"],
  .area-content:not([class*=bc-])>.container>.row-tile>.col-lg-9,
  .area-content:not([class*=bc-])>.container>.row>.col-lg-9 {
    color: $color;
    background: $color-bg;
    border-top: 2px solid $color;
    border-bottom: 2px solid $color;
  }

  .bc-main-theme:before {
    display: none;
  }
}

//схема для слабовидящих

#{$blind} {

  &::before {
    display: none;
  }

  .b-nav .nav {
    font-size: 0.9em;
  }

  .form-control {
    font-size: 1em;
    height: 2em;
  }

  .form-control-feedback {
    height: 2em;
    width: 2em;
  }

  input[type="submit"] {
    font-size: 1em;
  }
}

#{$blind}#{$blind-wb} {
  @include schema(rgba(0, 0, 0, 1), rgba(255, 255, 255, 1));
}

#{$blind}#{$blind-bw} {
  @include schema(rgba(255, 255, 255, 1), rgba(0, 0, 0, 1));
}

#{$blind}#{$blind-blue} {
  @include schema(rgba(6, 52, 98, 1), rgba(157, 209, 255, 1));

}

#{$blind}#{$blind-fs-xs} {
  font-size: 10px;
}

#{$blind}#{$blind-fs-s} {
  font-size: 13px;
}

#{$blind}#{$blind-fs-m} {
  font-size: 16px;
}

#{$blind}#{$blind-fs-l} {
  font-size: 19px;
}

#{$blind}#{$blind-fk-s} {
  letter-spacing: 1px;
}

#{$blind}#{$blind-fk-m} {
  letter-spacing: 2.5px;
}

#{$blind}#{$blind-fk-l} {
  letter-spacing: 4px;
}

#{$blind}#{$blind-images-false} {
  .image-src-box, img {
    display: none !important;
  }
}
